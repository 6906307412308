<template>
	<div>
		<AutoCompleteInput
			v-model="customer.id"
			hide-details
			:items="items"
			:id="inputId"
			:hide-top-margin="hideTopMargin"
			:loading="pageLoading"
			:disabled="disabled"
			:placeholder="placeholder"
			v-on:change="getCustomer($event)"
			:rules="rules"
			@blur="blur"
			@change="$emit('change1', customer.id)"
			:class="{ ...contentClass }"
		>
		</AutoCompleteInput>
		<div class="d-flex flex-wrap justify-space-between">
			<div class="pa-3 col-md-6 col-lg-3" v-if="!is_empty(customer_detail)">
				<div class="blue--text text--darken-4 text-uppercase">
					<b>Company Detail</b>
				</div>
				<p class="mb-0" v-if="customer_detail.company_name">
					<v-icon size="16" class="mr-1">mdi-home-city-outline</v-icon>
					<ShowValue
						tooltip
						tooltip-text="Company name"
						:object="customer_detail"
						object-key="company_name"
						label="company name"
					></ShowValue>
				</p>
				<p class="mb-0" v-if="customer_detail.display_name">
					<v-icon size="16" class="mr-1">mdi-account</v-icon>
					<ShowValue
						tooltip
						tooltip-text="display name"
						:object="customer_detail"
						object-key="display_name"
						label="display name"
					></ShowValue>
				</p>
				<p class="mb-0" v-if="customer_detail.phone">
					<v-icon size="16" class="mr-1">mdi-phone</v-icon>
					<ShowValue
						tooltip
						click-to-copy
						tooltip-text="phone number"
						:object="customer_detail"
						object-key="phone"
						label="phone number"
					></ShowValue>
				</p>
				<p class="mb-0 text-truncate" v-if="customer_detail.email">
					<v-icon size="16" class="mr-1">mdi-email</v-icon>
					<ShowValue
						tooltip
						click-to-copy
						tooltip-text="email"
						:object="customer_detail"
						object-key="email"
						label="email"
					></ShowValue>
				</p>
				<p class="mb-0 text-truncate" v-if="false && customer_detail.company_name">
					<v-icon size="16" class="mr-1">mdi-domain</v-icon>{{ customer_detail.company_name }}
				</p>
				<div
					class="d-flex"
					v-if="false && (customer_detail.address_line_1 || customer_detail.address_line_2)"
				>
					<div>
						<v-icon size="16" class="mr-1">mdi-map</v-icon>
					</div>
					<div>
						<p class="mb-0" v-if="customer_detail.address_line_1">
							{{ customer_detail.address_line_1 }}
						</p>
						<p class="mb-0" v-if="customer_detail.address_line_2">
							{{ customer_detail.address_line_2 }}
						</p>
						<p class="mb-0" v-if="customer_detail.unit_number">
							{{ customer_detail.unit_number }}
						</p>
						<p class="mb-0" v-if="customer_detail.address_country">
							{{ customer_detail.address_country }}
						</p>
						<p class="mb-0" v-if="customer_detail.address_postal_code">
							{{ customer_detail.address_postal_code }}
						</p>
					</div>
				</div>
			</div>
			<div class="pa-3 col-md-6 col-lg-3" v-if="!is_empty(contact_person)">
				<div class="d-flex justify-space-between no-wrap">
					<div>
						<div class="blue--text text--darken-4 text-uppercase no-wrap">
							<b>Person Incharge</b>
							<v-icon v-if="contact_person.primary" size="10" color="green" class="ml-1"
								>mdi-circle</v-icon
							>
							<v-menu offset-y max-width="300">
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										:disabled="pageLoading"
										v-bind="attrs"
										v-on="on"
										size="18"
										color="blue darken-4"
										class="ml-3 cursor-pointer"
										>mdi-pencil-circle</v-icon
									>
								</template>
								<v-list dense style="padding: 8px !important">
									<v-list-item-group>
										<template v-for="(item, index) in contact_person_list">
											<v-list-item
												@click="handleContactPerson(item.id)"
												:key="index"
												:class="{ 'blue lighten-4': item.id === contact_person.id }"
											>
												<!-- <v-list-item-avatar size="25" :class="item.primary ? 'green' : ''">
													<img
														:style="`border-radius: 99%; ${item.primary ? 'padding:2px' : ''}`"
														:src="$assetURL('media/users/default.jpg')"
														alt="profile"
													/>
												</v-list-item-avatar> -->
												<v-list-item-icon class="mr-2 position-relative">
													<v-icon :color="item.primary ? 'green' : ''">mdi-account-circle</v-icon>
													<v-icon v-if="item.primary" size="10" color="green" class="ml-1 billing-active"
														>mdi-circle</v-icon
													>
												</v-list-item-icon>
												<v-list-item-title class="text-truncate"
													>{{ item.barcode }}-{{ item.display_name }}</v-list-item-title
												>
											</v-list-item>
										</template>
									</v-list-item-group>
								</v-list>
							</v-menu>
						</div>
						<p class="mb-0" v-if="contact_person.display_name">
							<v-icon size="16" class="mr-1">mdi-account</v-icon>{{ contact_person.display_name }}
						</p>
						<p class="mb-0" v-if="contact_person.phone">
							<v-icon size="16" class="mr-1">mdi-phone</v-icon>{{ contact_person.phone }}
						</p>
						<p class="mb-0 text-truncate" v-if="contact_person.email">
							<v-icon size="16" class="mr-1">mdi-email</v-icon>
							{{ contact_person.email }}
						</p>
					</div>
					<div></div>
				</div>
			</div>
			<div class="pa-3 col-md-6 col-lg-3" v-if="!is_empty(default_billing_address)">
				<div class="d-flex justify-space-between">
					<div>
						<div class="blue--text text--darken-4 text-uppercase no-wrap">
							<b>Billing Address</b>
						</div>
						<p class="mb-0" v-if="default_billing_address.display_name">
							<v-icon size="16" class="mr-1">mdi-account</v-icon>{{ default_billing_address.display_name }}
						</p>
						<p class="mb-0" v-if="default_billing_address.phone_no">
							<v-icon size="16" class="mr-1">mdi-phone</v-icon>{{ default_billing_address.phone_no }}
						</p>
						<p class="mb-0 text-truncate" v-if="default_billing_address.email">
							<v-icon size="16" class="mr-1">mdi-email</v-icon>{{ default_billing_address.email }}
						</p>
						<div
							class="d-flex"
							v-if="default_billing_address.address_line_1 || default_billing_address.address_line_2"
						>
							<div>
								<v-icon size="16" class="mr-1">mdi-map</v-icon>
							</div>
							<div>
								<p class="mb-0" v-if="default_billing_address.address_line_1">
									{{ default_billing_address.address_line_1 }}
								</p>
								<p class="mb-0" v-if="default_billing_address.address_line_2">
									{{ default_billing_address.address_line_2 }}
								</p>
								<p class="mb-0" v-if="default_billing_address.unit_number">
									{{ default_billing_address.unit_number }}
								</p>
								<p class="mb-0" v-if="default_billing_address.address_country">
									{{ default_billing_address.address_country }}
								</p>
								<p class="mb-0" v-if="default_billing_address.address_postal_code">
									{{ default_billing_address.address_postal_code }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="pa-3 col-md-6 col-lg-3" v-if="serviceAddress && !is_empty(billing_address)">
				<div class="d-flex justify-space-between">
					<div>
						<div class="blue--text text--darken-4 text-uppercase no-wrap">
							<b>Service Address</b>
							<v-icon v-if="false && billing_address.default" size="10" color="green" class="ml-1"
								>mdi-circle</v-icon
							>
							<v-menu offset-y max-width="300">
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										:disabled="pageLoading"
										v-bind="attrs"
										v-on="on"
										size="18"
										color="blue darken-4"
										class="ml-3 cursor-pointer"
										>mdi-pencil-circle</v-icon
									>
								</template>
								<v-list dense style="padding: 8px !important">
									<v-list-item-group>
										<template v-for="(item, index) in billing_address_list">
											<v-list-item
												@click="handleBillingAddress(item.id)"
												:key="index"
												:class="{ 'blue lighten-4': item.id === billing_address.id }"
											>
												<v-list-item-icon class="mr-2 position-relative">
													<v-icon>mdi-map-marker</v-icon>
													<v-icon
														v-if="false && item.default"
														size="10"
														color="green"
														class="ml-1 billing-active"
														>mdi-circle</v-icon
													>
												</v-list-item-icon>
												<v-list-item-title class="text-truncate"
													>{{ item.barcode }}-{{ item.address_line_1 }}</v-list-item-title
												>
											</v-list-item>
										</template>
									</v-list-item-group>
								</v-list>
							</v-menu>
						</div>
						<p class="mb-0" v-if="billing_address.display_name">
							<v-icon size="16" class="mr-1">mdi-account</v-icon>{{ billing_address.display_name }}
						</p>
						<p class="mb-0" v-if="billing_address.phone_no">
							<v-icon size="16" class="mr-1">mdi-phone</v-icon>{{ billing_address.phone_no }}
						</p>
						<p class="mb-0 text-truncate" v-if="billing_address.email">
							<v-icon size="16" class="mr-1">mdi-email</v-icon>{{ billing_address.email }}
						</p>
						<div class="d-flex" v-if="billing_address.address_line_1 || billing_address.address_line_2">
							<div>
								<v-icon size="16" class="mr-1">mdi-map</v-icon>
							</div>
							<div>
								<p class="mb-0" v-if="billing_address.address_line_1">
									{{ billing_address.address_line_1 }}
								</p>
								<p class="mb-0" v-if="billing_address.address_line_2">
									{{ billing_address.address_line_2 }}
								</p>
								<p class="mb-0" v-if="billing_address.unit_number">
									{{ billing_address.unit_number }}
								</p>
								<p class="mb-0" v-if="billing_address.address_country">
									{{ billing_address.address_country }}
								</p>
								<p class="mb-0" v-if="billing_address.address_postal_code">
									{{ billing_address.address_postal_code }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import ShowValue from "@/view/components/ShowValue";
import { GetCustomer } from "@/core/lib/quotation.lib";
import { find, filter, isEmpty } from "lodash";

export default {
	name: "SelectCustomerSupplier",
	components: {
		AutoCompleteInput,
		ShowValue,
	},
	model: {
		prop: "value",
		event: "change",
	},
	props: {
		value: {
			type: Object,
			default: () => {
				return {};
			},
		},
		updateDetail: {
			type: Object,
			default: () => {
				return {};
			},
		},
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		serviceAddress: {
			type: Boolean,
			default: true,
		},
		items: {
			type: Array,
			default: () => {
				return [];
			},
		},
		inputId: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		rules: {
			type: Array,
			default: () => {
				return [];
			},
		},
		contentClass: {
			type: Object,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		updateDetail: {
			deep: true,
			immediate: true,
			handler(params) {
				this.customer = { ...params };
				this.getCustomer(params.id, params.billing_address_id, params.contact_person_id);
			},
		},
		loading(param) {
			this.pageLoading = param;
		},
	},
	data() {
		return {
			customer: {
				id: null,
				biiling_address_id: null,
				contact_person_id: null,
			},
			billing_address_list: [],
			billing_address: {},
			contact_person_list: [],
			contact_person: {},
			default_billing_address: {},
			customer_detail: {},
			pageLoading: false,
		};
	},
	methods: {
		async getCustomer(id, billing_id, contact_id) {
			const _this = this;
			if (!id) {
				_this.billing_address = {};
				_this.contact_person = {};
				_this.customer_detail = {};
				_this.default_billing_address = {};
				_this.emitCustomerId();
				return;
			}
			// let params = 'customer';
			// if(_this.type=='customer'){
			// 	params = {customer:id};
			// }else{
			// 	params = {supplier:id};
			// }
			_this.pageLoading = true;
			const params = { contact: id };
			const { current_contact, billing_addresses, contact_persons } = await GetCustomer(params);
			_this.billing_address_list = billing_addresses;
			_this.contact_person_list = contact_persons;
			_this.customer_detail = current_contact;
			_this.default_billing_address = find(billing_addresses, (row) => row.default);
			_this.pageLoading = false;
			if (!billing_id) {
				_this.contact_person = find(contact_persons, (row) => row.primary);
			} else {
				this.handleBillingAddress(billing_id);
			}
			if (!contact_id) {
				_this.billing_address = _this.billing_address_list[0];
			} else {
				_this.handleContactPerson(contact_id);
			}
			_this.emitCustomerId();
		},
		emitCustomerId() {
			const _this = this;
			_this.$emit("change", {
				id: _this.customer_detail && _this.customer_detail.id ? _this.customer_detail.id : null,
				billing_address_id:
					_this.billing_address && _this.billing_address.id ? _this.billing_address.id : null,
				contact_person_id:
					_this.contact_person && _this.contact_person.id ? _this.contact_person.id : null,
				property_id:
					_this.default_billing_address && _this.default_billing_address.id
						? _this.default_billing_address.id
						: null,
			});
		},
		is_empty(obj) {
			return isEmpty(obj);
		},
		blur(autocompleteinput) {
			this.$emit("blur", autocompleteinput);
		},
		handleBillingAddress(id) {
			const _address = find(this.billing_address_list, (row) => {
				return row.id == id;
			});
			this.billing_address = _address;
			this.emitCustomerId();
		},
		handleContactPerson(id) {
			const _contactPerson = find(this.contact_person_list, (row) => {
				return row.id == id;
			});
			this.contact_person = _contactPerson;
			this.emitCustomerId();
		},
		inilize() {
			if (this.value && this.value.id) {
				this.customer = {
					id: this.value.id ? this.value.id : null,
					billing_address_id: this.value.billing_address_id ? this.value.billing_address_id : null,
					contact_person_id: this.value.contact_person_id ? this.value.contact_person_id : null,
				};
				// let params = 'customer';
				// if(this.type=='customer'){
				// 	params = {customer:this.value.id};
				// }else{
				// 	params = {supplier:this.value.id};
				// }
				const params = { contact: this.value.id };
				GetCustomer(params).then(({ current_contact, billing_addresses, contact_persons }) => {
					this.customer_detail = current_contact;
					// this.billing_address_list = billing_addresses;
					this.billing_address_list = filter(billing_addresses, (row) => {
						return !row.default;
					});
					this.default_billing_address = find(billing_addresses, (row) => row.default);
					this.contact_person_list = contact_persons;
					if (this.customer.billing_address_id) {
						this.handleBillingAddress(this.customer.billing_address_id);
					}
					if (this.customer.contact_person_id) {
						this.handleContactPerson(this.customer.contact_person_id);
					}
				});
			}
		},
	},
	mounted() {
		this.getCustomer(this.value?.id, this.value?.biiling_address_id, this.value?.contact_person_id);
		//this.inilize()
	},
};
</script>
<style scoped>
.billing-active {
	position: absolute;
	left: -18px;
	top: 8px;
}
</style>
